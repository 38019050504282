var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-container uk-margin-small-top"},[_c('h2',[_vm._v("Discussions")]),_c('div',{staticClass:"uk-grid"},[_c('div',{staticClass:"uk-width-3-4@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small"},[_c('div',{staticClass:"uk-grid uk-align-right"},[_c('div',{staticClass:"uk-margin-1-4@m"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.show_discussions),expression:"show_discussions"}],staticClass:"uk-input uk-width-1-1",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.show_discussions=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.filter]}},[_c('option',{attrs:{"value":"0"}},[_vm._v("All")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Active Only")])])])]),_c('table',{staticClass:"uk-table uk-table-divider"},[_c('tbody',_vm._l((_vm.topics),function(topic,i){return _c('tr',{key:("discussion-" + i)},[_c('td',{attrs:{"width":"7%"}},[_c('router-link',{attrs:{"to":{
                    name: 'trader.discussions.show',
                    params: { id: topic.id },
                  }}},[_c('img',{class:("avatar " + (_vm.loading ? 'skeleton' : '')),attrs:{"src":topic.author.avatar,"alt":topic.title}})])],1),_c('td',{staticClass:"border-right"},[_c('router-link',{attrs:{"to":{
                    name: 'trader.discussions.show',
                    params: { id: topic.id },
                  }}},[_c('h5',{class:("uk-margin-remove " + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(topic.title)+" "),(topic.status == 'closed')?_c('span',{staticClass:"uk-text-danger"},[_vm._v("(Closed)")]):_vm._e()]),_c('p',{class:_vm.loading
                        ? 'skeleton skeleton-text'
                        : 'uk-margin-remove'},[_c('small',[_vm._v(_vm._s(_vm.$localizer.parse(topic.date))+" - "+_vm._s(topic.diff))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                    name: 'trader.discussions.show',
                    params: { id: topic.id },
                  }}},[_c('p',{staticClass:"uk-margin-remove text-black"},[_vm._v("Replies")]),_c('p',{class:_vm.loading
                        ? 'skeleton skeleton-text'
                        : 'uk-margin-remove'},[_vm._v(" "+_vm._s(topic.comments)+" ")])])],1)])}),0)])])]),_c('div',{staticClass:"uk-width-1-4@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-card-body uk-card-small"},[_c('h3',[_vm._v("Continue Learning")]),_c('p',[_vm._v("There are more videos in the academy.")]),_c('router-link',{staticClass:"uk-button uk-button-primary",attrs:{"to":{ name: 'trader.academy.index' }}},[_vm._v("Visit Academy")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }