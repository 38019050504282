<template>
  <div class="uk-container uk-margin-small-top">
    <h2>Discussions</h2>
    <div class="uk-grid">
      <div class="uk-width-3-4@m">
        <div class="uk-card uk-card-default uk-card-body uk-card-small">
          <div class="uk-grid uk-align-right">
            <div class="uk-margin-1-4@m">
              <select class="uk-input uk-width-1-1" @change="filter" v-model="show_discussions">
                <option value="0">All</option>
                <option value="1">Active Only</option>
              </select>
            </div>
          </div>
          <table class="uk-table uk-table-divider">
            <tbody>
              <tr v-for="(topic, i) in topics" :key="`discussion-${i}`">
                <td width="7%">
                  <router-link
                    :to="{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }"
                  >
                    <img
                      :src="topic.author.avatar"
                      :alt="topic.title"
                      :class="`avatar ${loading ? 'skeleton' : ''}`"
                    />
                  </router-link>
                </td>
                <td class="border-right">
                  <router-link
                    :to="{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }"
                  >
                    <h5
                      :class="`uk-margin-remove ${
                        loading ? 'skeleton skeleton-text' : ''
                      }`"
                    >
                      {{ topic.title }}
                      <span v-if="topic.status == 'closed'" class="uk-text-danger">(Closed)</span>
                    </h5>
                    <p
                      :class="
                        loading
                          ? 'skeleton skeleton-text'
                          : 'uk-margin-remove'
                      "
                    >
                      <small>{{ $localizer.parse(topic.date) }}  - {{ topic.diff }}</small>
                    </p>
                  </router-link>
                </td>
                <td width="10%">
                  <router-link
                    :to="{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }"
                  >
                    <p class="uk-margin-remove text-black">Replies</p>
                    <p
                      :class="
                        loading
                          ? 'skeleton skeleton-text'
                          : 'uk-margin-remove'
                      "
                    >
                      {{ topic.comments }}
                    </p>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-width-1-4@m">
        <div class="uk-card uk-card-default uk-card-body uk-card-small">
          <h3>Continue Learning</h3>
          <p>There are more videos in the academy.</p>
          <router-link :to="{ name: 'trader.academy.index' }" class="uk-button uk-button-primary">Visit Academy</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  mounted() {
    this.fetch();
  },

  data() {
    return {
      topics: [
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
      ],
      loading: true,
      show_discussions: 0,
      all_topics: []
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/trader/discussions").then((response) => {
        this.all_topics = response.data.topics;
        this.loading = false;
        this.filter()
      });
    },

    filter() {
      if(this.show_discussions == 0) {
        this.topics = this.all_topics
      } else {
        this.topics = this.all_topics.filter(topic => {
          return topic.status != 'closed'
        })
      }
    }
  },
};
</script>
